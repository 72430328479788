import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./Footer.css"

class Footer extends React.Component {
    render() {
        return <Row>
            <Card className='footerBackground'>
                <Card.Body>
                    <Row>
                        <Col className='footerLeft' xs={12} sm={6}>
                            <Row><a className='footerLink' href="/privacy">Privacy Policy</a></Row>
                            <Row><a className='footerLink' href="/terms">Terms of Service</a></Row>
                            <Row><a className="footerLink" href="https://www.vecteezy.com/free-vector/vector">Vector Vectors by Vecteezy</a></Row>
                        </Col>
                        <Col className='footerRight' xs={12} sm={6}>Contact: contact@hidemy.world</Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>
    }
}

export default Footer;
