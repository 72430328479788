import React from "react";
import autoBind from 'react-autobind';
import './FlowModal.css'
import { Button } from "react-bootstrap";
import { Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import Select from 'react-select';

class ForwardingSelection extends React.Component {

    constructor() {
        super();
        this.state = {
            phoneNumber: null,
            countryCode: null,
            otpSent: false,
            otpTyped: null,
            ready: false,
            loading: false,
            countries: [
                {"iso_code": "AR", "name": "Argentina", "country_code": "+54", "flag": "🇦🇷"},
                {"iso_code": "AU", "name": "Australia", "country_code": "+61", "flag": "🇦🇺"},
                {"iso_code": "AT", "name": "Austria", "country_code": "+43", "flag": "🇦🇹"},
                {"iso_code": "BB", "name": "Barbados", "country_code": "+1-246", "flag": "🇧🇧"},
                {"iso_code": "BJ", "name": "Benin", "country_code": "+229", "flag": "🇧🇯"},
                {"iso_code": "BA", "name": "Bosnia and Herzegovina", "country_code": "+387", "flag": "🇧🇦"},
                {"iso_code": "BR", "name": "Brazil", "country_code": "+55", "flag": "🇧🇷"},
                {"iso_code": "BG", "name": "Bulgaria", "country_code": "+359", "flag": "🇧🇬"},
                {"iso_code": "CL", "name": "Chile", "country_code": "+56", "flag": "🇨🇱"},
                {"iso_code": "CO", "name": "Colombia", "country_code": "+57", "flag": "🇨🇴"},
                {"iso_code": "HR", "name": "Croatia", "country_code": "+385", "flag": "🇭🇷"},
                {"iso_code": "CY", "name": "Cyprus", "country_code": "+357", "flag": "🇨🇾"},
                {"iso_code": "CZ", "name": "Czech Republic", "country_code": "+420", "flag": "🇨🇿"},
                {"iso_code": "DO", "name": "Dominican Republic", "country_code": "+1-809, +1-829, +1-849", "flag": "🇩🇴"},
                {"iso_code": "EC", "name": "Ecuador", "country_code": "+593", "flag": "🇪🇨"},
                {"iso_code": "SV", "name": "El Salvador", "country_code": "+503", "flag": "🇸🇻"},
                {"iso_code": "EE", "name": "Estonia", "country_code": "+372", "flag": "🇪🇪"},
                {"iso_code": "FR", "name": "France", "country_code": "+33", "flag": "🇫🇷"},
                {"iso_code": "DE", "name": "Germany", "country_code": "+49", "flag": "🇩🇪"},
                {"iso_code": "GH", "name": "Ghana", "country_code": "+233", "flag": "🇬🇭"},
                {"iso_code": "GR", "name": "Greece", "country_code": "+30", "flag": "🇬🇷"},
                {"iso_code": "GD", "name": "Grenada", "country_code": "+1-473", "flag": "🇬🇩"},
                {"iso_code": "HU", "name": "Hungary", "country_code": "+36", "flag": "🇭🇺"},
                {"iso_code": "ID", "name": "Indonesia", "country_code": "+62", "flag": "🇮🇩"},
                {"iso_code": "IE", "name": "Ireland", "country_code": "+353", "flag": "🇮🇪"},
                {"iso_code": "IL", "name": "Israel", "country_code": "+972", "flag": "🇮🇱"},
                {"iso_code": "JM", "name": "Jamaica", "country_code": "+1-876", "flag": "🇯🇲"},
                {"iso_code": "JP", "name": "Japan", "country_code": "+81", "flag": "🇯🇵"},
                {"iso_code": "KE", "name": "Kenya", "country_code": "+254", "flag": "🇰🇪"},
                {"iso_code": "MO", "name": "Macau", "country_code": "+853", "flag": "🇲🇴"},
                {"iso_code": "ML", "name": "Mali", "country_code": "+223", "flag": "🇲🇱"},
                {"iso_code": "MU", "name": "Mauritius", "country_code": "+230", "flag": "🇲🇺"},
                {"iso_code": "MX", "name": "Mexico", "country_code": "+52", "flag": "🇲🇽"},
                {"iso_code": "NZ", "name": "New Zealand", "country_code": "+64", "flag": "🇳🇿"},
                {"iso_code": "PA", "name": "Panama", "country_code": "+507", "flag": "🇵🇦"},
                {"iso_code": "PH", "name": "Philippines", "country_code": "+63", "flag": "🇵🇭"},
                {"iso_code": "RO", "name": "Romania", "country_code": "+40", "flag": "🇷🇴"},
                {"iso_code": "SI", "name": "Slovenia", "country_code": "+386", "flag": "🇸🇮"},
                {"iso_code": "ZA", "name": "South Africa", "country_code": "+27", "flag": "🇿🇦"},
                {"iso_code": "SD", "name": "Sudan", "country_code": "+249", "flag": "🇸🇩"},
                {"iso_code": "CH", "name": "Switzerland", "country_code": "+41", "flag": "🇨🇭"},
                {"iso_code": "TH", "name": "Thailand", "country_code": "+66", "flag": "🇹🇭"},
                {"iso_code": "TN", "name": "Tunisia", "country_code": "+216", "flag": "🇹🇳"},
                {"iso_code": "UG", "name": "Uganda", "country_code": "+256", "flag": "🇺🇬"},
                {"iso_code": "GB", "name": "United Kingdom", "country_code": "+44", "flag": "🇬🇧"}
              ]
        }
        autoBind(this);
    }

    sendOTP() {
        this.setState({loading: true})
        return fetch(process.env.REACT_APP_API_URL+'/forward/verify', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phone_number: this.state.phoneNumber,
                country_code: this.state.countryCode.label.country_code
            })
            })
            .then(response => {
                if(!response.ok) {
                    toast.error("Failed to send confirmation SMS")
                }
            return response.json()
            }
            
        )
            .then(data => {
            if(!data.success) {
                toast.error(data.error || 'Failed to send confirmation SMS')
                this.setState({loading: false})
                return
            }

            this.setState({
                otpSent: true,
                loading: false
            })
            toast.success('SMS sent')
            })
            .catch((error) => {
                this.setState({loading: false})
            toast.error(error)
            });
    }

    renderOTP() {
        return <div>
            <b>One Time Password</b>
            <Row>
                <Col sm={7}>
                    <Form.Control type="number" value={this.state.otpTyped} onChange={(e) => this.setState({otpTyped: e.target.value})} placeholder="Enter the code you received by text" />
                </Col>
                <Col sm={5}>
                    <Button disabled={this.loading} onClick={this.sendOTP} variant="light">Resend OTP</Button>
                </Col>
            </Row>
            
        </div>
    }

    validateOtp() {

        this.setState({loading: true})
        return fetch(process.env.REACT_APP_API_URL+'/register-with-otp', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phone_number: this.state.phoneNumber,
                country_code: this.state.countryCode.label.country_code,
                otp: this.state.otpTyped,
                email: this.props.email
            })
            })
            .then(response => {
                if(!response.ok) {
                    toast.error("Failed to verify OTP, try again")
                }
            return response.json()
            }
            
        )
            .then(data => {

            if(!data.access_token) {
                toast.error(data.error || 'Failed to verify OTP, try again')
                this.setState({loading: false})
                return
            }

            localStorage.setItem('token', data.access_token)
            this.props.callback(this.state.phoneNumber)
            this.setState({
                otpSent: true,
                loading: false
            })
            })
            .catch((error) => {
                this.setState({loading: false})
                toast.error(error)
            });
        
    }

    validateOtpDashboard() {
        this.setState({loading: true})
        return fetch(process.env.REACT_APP_API_URL+'/register-with-otp', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phone_number: this.state.phoneNumber,
                country_code: this.state.countryCode.label.country_code,
                otp: this.state.otpTyped
            })
            })
            .then(response => {
                if(!response.ok) {
                    toast.error("Failed to verify OTP, try again")
                }
            return response.json()
            }
            
        )
            .then(data => {

            if(!data.access_token) {
                toast.error(data.error || 'Failed to verify OTP, try again')
                this.setState({loading: false})
                return
            }

            localStorage.setItem('token', data.access_token)
            this.props.callback(this.state.phoneNumber)
            this.setState({
                otpSent: true,
                loading: false
            })
            })
            .catch((error) => {
                this.setState({loading: false})
                toast.error(error)
            });
    }


    renderNext() {
        if (!this.state.otpSent) {
            return <Button disabled={this.loading || !this.state.countryCode || !this.state.phoneNumber} onClick={() => this.sendOTP()}>{this.state.loading ? <Loader type="ThreeDots" height={'100%'} color="white" /> : 'CONFIRM NUMBER'}</Button>
        }
        return <Button disabled={this.loading || !this.state.countryCode || !this.state.phoneNumber || !this.state.otpTyped} onClick={this.validateOtp}>NEXT</Button>
    }

    formatOptionLabel(data) {
        return  <div><span>{data.label.name}</span> ({data.label.country_code})</div>
    }

    render() {
        const options = this.state.countries.map((country) => ({
            value: country.iso_code,
            label: country,
          }));
        return <>
            <h5>Setup your virtual number</h5>
            <p>Enter the phone number to forward calls/texts from your virtual number to finalize your account</p>
            <div>
                <b>Phone number</b>
                <Row>
                    <Col sm={4}><Select
              options={options}
              value={this.state.selectedCountry}
              onChange={(selected) => this.setState({countryCode: selected})}
              formatOptionLabel={this.formatOptionLabel}
              isClearable
              placeholder="Country"
            /></Col>
                    <Col sm={8}> <Form.Control type="number" value={this.state.phoneNumber} onChange={(e) => this.setState({phoneNumber: e.target.value})} placeholder="Enter your phone number" /></Col>
                </Row>
               
                <br/>
                {this.state.otpSent ? this.renderOTP() : null}
            </div>
            <div className="center-align">
                <hr/>
                <Button onClick={() => this.props.backwards()} variant='light'>PREVIOUS</Button>
                {' '}
                {this.renderNext()}
            </div>
            
        </>
    }
  }
export default ForwardingSelection;