import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import MainNavigationBar from './MainNavigationBar';
import HomePage from './HomePage';
import LoggedHomePage from './LoggedHomePage';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <Router>
      <div className="App">
        <ToastContainer />
        <MainNavigationBar />
          <Routes>
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/dashboard" element={<LoggedHomePage />} />
            <Route path="/" element={<HomePage/>} />
          </Routes>
          <Footer/>
      </div>
    </Router>
  );
}

export default App;
