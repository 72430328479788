import React from "react";
import { Modal, Button, Form, Col, Row, Container } from "react-bootstrap";
import autoBind from 'react-autobind';
import { toast } from 'react-toastify';
import './LoginModal.css'

class LoginModal extends React.Component {

    constructor() {
        super();
        this.state = {
            register: true,
            email: null,
            name: null,
            password: null,
            password_two: null,
            loading: false,
            errors: {},
        }
        autoBind(this);
    }

    sendLogin() {
      this.setState({loading: true})
      fetch(process.env.REACT_APP_API_URL+'/login', {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: this.state.email,
            password: this.state.password,
        }),
      })
      .then(response => {
        this.setState({loading: false})
        return response.json()
      })
      .then(data => {
        if(!data.access_token) {
          return toast.error('Failed to login')
        }
        localStorage.setItem('token', data.access_token)
        window.location.href = '/dashboard'
      })
      .catch((error) => {
        this.setState({loading: false})
      });
    }

    renderLogin() {
      return <Form>
        <Col xs={12}>
            <Row>
              <b>Email:</b>
              <Col xs={12}><Form.Control type="email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} placeholder="Enter your email" /></Col>
            </Row>
            <br/>
            <Row>
              <b>Password:</b>
              <Col xs={12}><Form.Control type="password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} placeholder="Password" /></Col>
            </Row>
        </Col>
          <br/>
          <Button onClick={this.sendLogin}>Login</Button>
        </Form>
    }

    render() {
        return <Modal show={this.props.openModal} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body><Container>{this.renderLogin()}</Container></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    }
  }
export default LoginModal;