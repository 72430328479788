import React from "react";
import autoBind from 'react-autobind';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faGlobe, faComment } from '@fortawesome/free-solid-svg-icons'
import './FlowModal.css'

class UsageSelection extends React.Component {

    constructor() {
        super();
        this.state = {
            selectedUsage: null
        }
        autoBind(this);
    }

    render() {
        return<>
            <h5>Select your usage</h5>
            <div className="center-align">
                <Button onClick={() => this.setState({selectedUsage: 'voice'})} className="usage-selection" variant={this.state.selectedUsage === 'voice' ? "success" : "dark"}>
                    <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                    <div className="small-font">VOICE</div>
                </Button>
                <Button onClick={() => this.setState({selectedUsage: 'text'})}  className="usage-selection" variant={this.state.selectedUsage === 'text' ? "success" : "dark"}>
                    <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>
                    <div className="small-font">TEXT</div>
                </Button>
                <Button onClick={() => this.setState({selectedUsage: 'all'})}  className="usage-selection" variant={this.state.selectedUsage === 'all' ? "success" : "dark"}>
                    <FontAwesomeIcon icon={faGlobe}></FontAwesomeIcon>
                    <div className="small-font">BOTH</div>
                </Button>
                <hr/>
                {this.props.backwards ? <Button onClick={() => this.props.backwards()} variant='light'>PREVIOUS</Button> : null}
                {' '}
                <Button disabled={!this.state.selectedUsage} onClick={() => this.props.callback(this.state.selectedUsage)}>NEXT</Button>
            </div>
            
        </>

    }
  }
export default UsageSelection;