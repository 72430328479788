import React from "react";
import { Modal, Container } from "react-bootstrap";
import autoBind from 'react-autobind';
import CountrySelection from "./CountrySelection";
import UsageSelection from "./UsageSelection";
import ForwardingSelection from "./ForwardingSelection";
import PlanSelection from "./PlanSelection";
import EmailSelection from "./EmailSelection";
import FlowPayment from "./FlowPayment";
import './FlowModal.css'

class FlowModal extends React.Component {

    constructor() {
        super();

        let currentStep = localStorage.getItem('token') ? 4 : 0
        this.state = {
            step: currentStep,
            totalSteps: 4,
            country: null,
            usage: null,
            email: null,
            userInformations: null,
            selectedPlan: null,
            errors: {},
        }
        autoBind(this);
    }

    countrySelectionCallback(country) {
        this.setState({
            country: country,
            step: this.state.step+1
        })
    }

    usageSelectionCallback(usage) {
        this.setState({
            usage: usage,
            step: this.state.step+1
        })
    }

    forwardSelectionCallback(userInformations) {
        this.setState({
            userInformations: userInformations,
            step: this.state.step+1
        })
    }

    planSelectionCallback(selectedPlan) {
        this.setState({
            selectedPlan: selectedPlan,
            step: this.state.step+1
        })
    }

    paymentSuccessCallback(paymentCallback) {
        this.setState({

        })
    }

    emailSelectionCallback(email) {

        let nextStep = this.state.step+1

        if (this.state.usage === 'text') {
            nextStep++
        }
        this.setState({
            email: email,
            step: nextStep
        })
    }


    renderStep() {
        const flowSteps = [
            <CountrySelection callback={this.countrySelectionCallback}></CountrySelection>,
            <UsageSelection callback={this.usageSelectionCallback} backwards={() => this.setState({step: this.state.step-1})}></UsageSelection>,
            <EmailSelection shouldRegister={this.state.usage === 'text'} callback={this.emailSelectionCallback} backwards={() => this.setState({step: this.state.step-1})}></EmailSelection>,
            <ForwardingSelection email={this.state.email} callback={this.forwardSelectionCallback} backwards={() => this.setState({step: this.state.step-1})}></ForwardingSelection>,
            <PlanSelection callback={this.planSelectionCallback} backwards={() => this.setState({step: this.state.step-1})}></PlanSelection>,
            <FlowPayment backwards={() => this.setState({step: this.state.step -1})} selectedPlan={this.state.selectedPlan} country={this.state.country} callback={this.paymentSuccessCallback}></FlowPayment>
        ]
        return flowSteps[this.state.step]
    }

    renderModalHeader() {
        if(this.state.step+1 > this.state.totalSteps) {
            return
        }
        return <>Step {this.state.step+1}/{this.state.totalSteps}</>
    }

    render() {
        return <Modal show={this.props.openModal} onHide={this.props.handleClose}>
        <Modal.Header closeButton>{this.renderModalHeader()}</Modal.Header>
        <Modal.Body>
            <Container>
                {this.renderStep()}
            </Container>
        </Modal.Body>
      </Modal>
    }
  }
export default FlowModal;