import React from "react";
import { Card, Row, ProgressBar, Col, Button, Badge, ListGroup, Modal, Container} from "react-bootstrap";
import './LoggedHomePage.css';
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faPlusCircle, faTrashAlt, faEdit, faRedo } from '@fortawesome/free-solid-svg-icons'
import { toast } from "react-toastify";
import CountrySelection from "./CountrySelection";
import UsageSelection from "./UsageSelection"
import ForwardingSelection from "./ForwardingSelection";
class LoggedHomePage extends React.Component {

    constructor() {
        super()
        autoBind(this);
        this.state = {
            me: null,
            openPhoneModal: false,
            phoneStep: 'country',
            selectedPhone: null
        }
        this.loadMeData()
    }
    loadMeData() {
        let token = localStorage.getItem('token')
        if(!token) {
          window.location.replace('/')
          return;
        }
    
        fetch(process.env.REACT_APP_API_URL+'/me', {
          method: 'GET', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({me: data})
            if(!data.has_active_plan) {
                window.location.replace('/')
                return
            }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    createPhoneNumber(values) {
        let token = localStorage.getItem('token')
        if(!token) {
          return;
        }

        let forwardTo = null
        if(values.selectedForward) {
            forwardTo = {uuid: values.selectedForward}
        }
        return fetch(process.env.REACT_APP_API_URL+'/phone', {
            method: 'POST', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                name: values.name,
                forward_to: forwardTo,
                country_code: values.selectedCountry,
                iso_code: values.isoCode
            })
          })
          .then(response => {
              if(!response.ok) {
                  toast.error("Failed to create phone number")
              }
            return response.json()
          }
            
        )
          .then(data => {
            if(!data.success) {
                toast.error(data.error || 'Failed to create phone number')
                return
            }

            this.setState({
                openPhoneModal: false
            })
            toast.success('Phone number created with success')
            this.loadMeData()
          })
          .catch((error) => {
            toast.error(error)
            console.error('Error:', error);
          });
    }

    updatePhoneNumber(phoneNumber, values) {
        let token = localStorage.getItem('token')
        if(!token) {
          return;
        }
        return fetch(process.env.REACT_APP_API_URL+'/phone/'+phoneNumber.uuid, {
            method: 'PUT', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                name: values.name,
                forward_to: {uuid: values.selectedForward}
            })
          })
          .then(response => response.json())
          .then(data => {
            if(!data.success) {
                toast.error(data.error || 'Failed to update phone number')
                return
            }

            this.setState({
                openPhoneModal: false
            })
            toast.success('Phone number updated with success')
            this.loadMeData()
          })
          .catch((error) => {
            toast.error(error)
            console.error('Error:', error);
          });
    }

    deletePhoneNumber(phoneNumber) {
        let token = localStorage.getItem('token')
        if(!token) {
          return;
        }
        fetch(process.env.REACT_APP_API_URL+'/phone/'+phoneNumber.uuid, {
            method: 'DELETE', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+token
            },
          })
          .then(response => response.json())
          .then(data => {
            if(!data.success) {
                toast.error(data.error || 'Failed to delete phone number')
                return
            }
            toast.success('Phone number deleted with success')
            this.loadMeData()
          })
          .catch((error) => {
            toast.error(error)
            console.error('Error:', error);
          });
    }

    openPhoneModal(phoneNumber) {
        this.setState({
            selectedPhone: phoneNumber,
            openPhoneModal: true
        })
    }

    closePhoneModal() {
        this.setState({
            selectedPhone: null,
            openPhoneModal: false
        })
    }


    renderPlanLimits() {
        return <Card>
        <Card.Body>
            <Card.Title>Current usage:</Card.Title>
            <Card.Text>
               <Row>
                    <b>Phone numbers in use:</b>
                    {!this.state.me.activePlan ? 'None' : <ProgressBar now={(this.state.me.activePlan.usedNumbers / this.state.me.activePlan.totalPhones) * 100} label={`${this.state.me.activePlan.usedNumbers}/${this.state.me.activePlan.totalPhones}`} />}
               </Row>
               <Row>
                    <b>Call minutes used:</b>
                    {!this.state.me.activePlan ? 'None' : <ProgressBar now={(this.state.me.activePlan.usedMinutes / this.state.me.activePlan.totalMinutes) * 100} label={`${this.state.me.activePlan.usedMinutes}/${this.state.me.activePlan.totalMinutes}`} />}
               </Row>
            </Card.Text>
        </Card.Body>
    </Card>
    }

    renderContactListing() {
        return <Card>
        <Card.Body>
            <Card.Title>Available contacts</Card.Title>
            <Card.Text>
               <Row><Button onClick={() => this.openPhoneModal(null)} className='cta-button'><FontAwesomeIcon icon={faPlusCircle} /> Add new phone number</Button></Row>
               <Row>
                    <ListGroup>
                        {this.state.me.activePlan ? this.state.me.activePlan.phones.map(phone => this.renderPhone(phone)) : null}
                        
                    </ListGroup>

               </Row>
            </Card.Text>
        </Card.Body>
    </Card>
    }

    usageSelected(usage) {
        if(usage === 'text' || true) {
            return this.createPhoneNumber({
                selectedCountry: this.state.newCountry.label.country_code,
                isoCode: this.state.newCountry.label.iso_code,
                name: this.state.newCountry.label.name,
                phoneStep: 'country',
                openPhoneModal: false
            })
        }
        this.setState({
            phoneStep: 'forward',
            usage: usage,
        })
    }



    renderNewNumberFlow() {
        
        if(this.state.phoneStep === 'country') {
            return <CountrySelection callback={(country) => this.setState({newCountry: country, phoneStep: 'usage'})} />
        }

        if(this.state.phoneStep === 'usage') {
            return <UsageSelection backwards={() => this.setState({phoneStep: 'country'})} callback={this.usageSelected} />
        }

        if(this.state.phoneStep === 'forward') {
            return <ForwardingSelection/>
        }
    }

    renderPhone(phone) {
        return <ListGroup.Item>
            <div className='ms-2 me-auto'>
                <div className="fw-bold"><FontAwesomeIcon icon={faPhone} /> {phone.name}</div>
                <Row>
                    <Col xs={12}>
                        <Badge bg="dark">Virtual number: <b>{phone.phone}</b></Badge>
                    </Col>
                    <Col xs={12}>
                        <Badge bg="light" className='badgeForwardTo'>Forward to: {phone.redirect_to ? <i>{phone.redirect_to.value.country_code} {phone.redirect_to.value.phone_number}</i> :<i>No forward setup</i>}</Badge>
                    </Col>
                    <Col xs={12}>
                        <Button className='contactBtn'><FontAwesomeIcon onClick={() => this.deletePhoneNumber(phone)} icon={faTrashAlt} /></Button>
                    </Col>
                    <Col xs={12}>
                        <ListGroup>
                            <ListGroup.Item>SMS Received <Button onClick={this.loadMeData}><FontAwesomeIcon icon={faRedo} /></Button></ListGroup.Item>
                            {phone.texts?.map((text) => <ListGroup.Item>
                                <Badge bg="secondary">From: {text.from}</Badge>
                                <br/>
                                <pre>{text.content}</pre>
                                <Badge bg="secondary">Date: {text.created_at}</Badge>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Col>
                </Row>
            </div>
        </ListGroup.Item>
    }
    renderEmail(email) {
        return <tr>
        <th scope="row"><FontAwesomeIcon icon={faEnvelope} /> {email.name}</th>
        <td>{email.email}</td>
        <td>{email.redirect_to.value}</td>
        <td>{email.used}</td>
        <td><Button className='contactBtn'><FontAwesomeIcon icon={faEdit} /></Button><Button className='contactBtn'><FontAwesomeIcon icon={faTrashAlt} /></Button></td>
    </tr>   
    }

    customModal() {
        return <Modal show={this.state.openPhoneModal} onHide={() => this.setState({openPhoneModal: false})}>
        <Modal.Header closeButton>Add new phone</Modal.Header>
        <Modal.Body>
            <Container>
                {this.renderNewNumberFlow()}
            </Container>
        </Modal.Body>
      </Modal>
    }

    render() {

        if(!this.state.me) {
            return <b>Loading...</b>
        }

        return <div>
            {this.customModal()}
            <Row>
                <Col xs={12} sm={6}>
                    {this.renderPlanLimits()}
                </Col>
                <Col xs={12} sm={6}>
                    {this.renderContactListing()}
                </Col>
            </Row>
            
        </div>
    }
}

export default LoggedHomePage;