import React from "react";
import autoBind from 'react-autobind';
import { Button } from "react-bootstrap";
import './FlowModal.css'
import Select from 'react-select';

class CountrySelection extends React.Component {

    constructor() {
        super();
        this.state = {
            selectedCountry: null,
            countries: [
                {"iso_code": "AR", "name": "Argentina", "country_code": "+54", "flag": "🇦🇷"},
                {"iso_code": "AU", "name": "Australia", "country_code": "+61", "flag": "🇦🇺"},
                {"iso_code": "AT", "name": "Austria", "country_code": "+43", "flag": "🇦🇹"},
                {"iso_code": "BB", "name": "Barbados", "country_code": "+1-246", "flag": "🇧🇧"},
                {"iso_code": "BJ", "name": "Benin", "country_code": "+229", "flag": "🇧🇯"},
                {"iso_code": "BA", "name": "Bosnia and Herzegovina", "country_code": "+387", "flag": "🇧🇦"},
                {"iso_code": "BR", "name": "Brazil", "country_code": "+55", "flag": "🇧🇷"},
                {"iso_code": "BG", "name": "Bulgaria", "country_code": "+359", "flag": "🇧🇬"},
                {"iso_code": "CL", "name": "Chile", "country_code": "+56", "flag": "🇨🇱"},
                {"iso_code": "CO", "name": "Colombia", "country_code": "+57", "flag": "🇨🇴"},
                {"iso_code": "HR", "name": "Croatia", "country_code": "+385", "flag": "🇭🇷"},
                {"iso_code": "CY", "name": "Cyprus", "country_code": "+357", "flag": "🇨🇾"},
                {"iso_code": "CZ", "name": "Czech Republic", "country_code": "+420", "flag": "🇨🇿"},
                {"iso_code": "DO", "name": "Dominican Republic", "country_code": "+1-809, +1-829, +1-849", "flag": "🇩🇴"},
                {"iso_code": "EC", "name": "Ecuador", "country_code": "+593", "flag": "🇪🇨"},
                {"iso_code": "SV", "name": "El Salvador", "country_code": "+503", "flag": "🇸🇻"},
                {"iso_code": "EE", "name": "Estonia", "country_code": "+372", "flag": "🇪🇪"},
                {"iso_code": "FR", "name": "France", "country_code": "+33", "flag": "🇫🇷"},
                {"iso_code": "DE", "name": "Germany", "country_code": "+49", "flag": "🇩🇪"},
                {"iso_code": "GH", "name": "Ghana", "country_code": "+233", "flag": "🇬🇭"},
                {"iso_code": "GR", "name": "Greece", "country_code": "+30", "flag": "🇬🇷"},
                {"iso_code": "GD", "name": "Grenada", "country_code": "+1-473", "flag": "🇬🇩"},
                {"iso_code": "HU", "name": "Hungary", "country_code": "+36", "flag": "🇭🇺"},
                {"iso_code": "ID", "name": "Indonesia", "country_code": "+62", "flag": "🇮🇩"},
                {"iso_code": "IE", "name": "Ireland", "country_code": "+353", "flag": "🇮🇪"},
                {"iso_code": "IL", "name": "Israel", "country_code": "+972", "flag": "🇮🇱"},
                {"iso_code": "JM", "name": "Jamaica", "country_code": "+1-876", "flag": "🇯🇲"},
                {"iso_code": "JP", "name": "Japan", "country_code": "+81", "flag": "🇯🇵"},
                {"iso_code": "KE", "name": "Kenya", "country_code": "+254", "flag": "🇰🇪"},
                {"iso_code": "MO", "name": "Macau", "country_code": "+853", "flag": "🇲🇴"},
                {"iso_code": "ML", "name": "Mali", "country_code": "+223", "flag": "🇲🇱"},
                {"iso_code": "MU", "name": "Mauritius", "country_code": "+230", "flag": "🇲🇺"},
                {"iso_code": "MX", "name": "Mexico", "country_code": "+52", "flag": "🇲🇽"},
                {"iso_code": "NZ", "name": "New Zealand", "country_code": "+64", "flag": "🇳🇿"},
                {"iso_code": "PA", "name": "Panama", "country_code": "+507", "flag": "🇵🇦"},
                {"iso_code": "PH", "name": "Philippines", "country_code": "+63", "flag": "🇵🇭"},
                {"iso_code": "RO", "name": "Romania", "country_code": "+40", "flag": "🇷🇴"},
                {"iso_code": "SI", "name": "Slovenia", "country_code": "+386", "flag": "🇸🇮"},
                {"iso_code": "ZA", "name": "South Africa", "country_code": "+27", "flag": "🇿🇦"},
                {"iso_code": "SD", "name": "Sudan", "country_code": "+249", "flag": "🇸🇩"},
                {"iso_code": "CH", "name": "Switzerland", "country_code": "+41", "flag": "🇨🇭"},
                {"iso_code": "TH", "name": "Thailand", "country_code": "+66", "flag": "🇹🇭"},
                {"iso_code": "TN", "name": "Tunisia", "country_code": "+216", "flag": "🇹🇳"},
                {"iso_code": "UG", "name": "Uganda", "country_code": "+256", "flag": "🇺🇬"},
                {"iso_code": "GB", "name": "United Kingdom", "country_code": "+44", "flag": "🇬🇧"}
              ]
        }
        autoBind(this);
    }

    formatOptionLabel(data) {
        return  <div>
        <span>{data.label.flag}</span> {data.label.name}
      </div>
    }

    render() {        
          const options = this.state.countries.map((country) => ({
            value: country.iso_code,
            label: country,
          }));
        
          return <>
            <h5>Which country do you want your virtual number to be in ?</h5>
            <Select
              options={options}
              value={this.state.selectedCountry}
              onChange={(selected) => this.setState({selectedCountry: selected})}
              formatOptionLabel={this.formatOptionLabel}
              isClearable
              placeholder="Select a country"
            />
            <hr/>
            <div className="center-align">
            <Button disabled={!this.state.selectedCountry} onClick={() => this.props.callback(this.state.selectedCountry)}>NEXT</Button>
            </div>
            
          </>;
    }
  }
export default CountrySelection;