import React from "react";
import { Navbar, Nav, Container, NavDropdown} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faGlobe} from "@fortawesome/free-solid-svg-icons";
import './MainNavigationBar.css';
import autoBind from 'react-autobind';
import LoginModal from "./LoginModal";

class MainNavigationBar extends React.Component {

    constructor() {
        super();
        this.state = {
          loginModal: false
        }
        autoBind(this);
    }
    renderDropdown() {
        let token = localStorage.getItem('token')
        let userLoggedIn = token;

        if (userLoggedIn) {
            return <NavDropdown title="My account" id="basic-nav-dropdown">
            <NavDropdown.Item href="/dashboard">Phone numbers & Emails</NavDropdown.Item>
            <NavDropdown.Item onClick={this.logout}>Logout</NavDropdown.Item>
          </NavDropdown>
        }
        return <NavDropdown title="My account" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => this.setState({loginModal: true})}>Sign in</NavDropdown.Item>
          </NavDropdown>
    }

    logout() {
      localStorage.removeItem('token')
      window.location.reload(false)
    }

    openModal() {
        this.setState({
            openModal: true
        })
    }
    render() {
        return <Navbar className='customNav' bg="light" variant="light" expand="lg">
        <Container className='customNav'>
          <LoginModal openModal={this.state.loginModal} handleClose={() => this.setState({loginModal: false})}/>
          <Navbar.Brand href="/"><FontAwesomeIcon icon={faPhone} />{' '}<FontAwesomeIcon icon={faGlobe} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {this.renderDropdown()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    }
  }
export default MainNavigationBar;