import React from "react";
import { Row, Button, Card } from "react-bootstrap";
import './PaymentModal.css';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {faCcVisa, faCcAmex, faCcMastercard, faCcStripe} from '@fortawesome/free-brands-svg-icons'
import { toast } from 'react-toastify';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import autoBind from 'react-autobind';
import Loader from "react-loader-spinner";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

class FlowPayment extends React.Component {

    constructor(props) {
        super()
        autoBind(this);
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
        this.state = {
            stripePromise: stripePromise,
            cards: [],
            loading: false,
            nextBilling: null,
            address: null,
        }
    }

    mapCreditLogo(type) {
        if(type === 'amex') {
            return faCcAmex
        }

        if(type === 'mastercard') {
            return faCcMastercard
        }

        if(type === 'visa') {
            return faCcVisa
        }

        return faCcStripe
    }
    
    payAndActivateSubscription(card_token, stripe, card) {
        let token = localStorage.getItem('token')
        this.setState({loading: true})
        return fetch(process.env.REACT_APP_API_URL+'/payment/flow', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                plan_id: this.props.selectedPlan.id,
                card_token: card_token,
                country_code: this.props.country.label.country_code,
                iso_code: this.props.country.label.iso_code
            })
          }).then(response => response.json())
          .then(data => {
              if(!data.success) {
                  if(data.client_secret) {
                    console.log(data)
                    return stripe.confirmCardPayment(data.client_secret, {
                                payment_method: {
                                card: card
                                },
                            })
                            .then(this.validate3DS);
                  } else {
                    this.setState({loading: false})
                    toast.error(data.error || 'An error has occurred, check your card details')
                    return null
                  }
                  
              }
              //Redirect to the client dashboard
              toast.success('Purchase success');
              window.dataLayer.push({
                'event': 'user-paid'
              })
              window.location.replace('/dashboard');
              
          })
          .catch((error) => {
            this.setState({loading: false})
            console.log(error)
            toast.error('Failed to confirm order')
          });
    }

    validate3DS(result) {
        if(result.error) {
            this.setState({loading: false})
            toast.error(result.error)
            return null
        }
        const payment_intent_id = result.paymentIntent.id
        let token = localStorage.getItem('token')
        return fetch(process.env.REACT_APP_API_URL+'/payment/flow/3ds', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                plan_id: this.props.selectedPlan.id,
                payment_intent_id: payment_intent_id,
                country_code: this.props.country.label.country_code,
                iso_code: this.props.country.label.iso_code
            })
          }).then(response => response.json())
          .then(data => {
              if(!data.success) {
                toast.error(data.error || 'An error occurred in validating your payment, please try again')
                  
              } else {
              //Redirect to the client dashboard
                toast.success('Purchase success');
                window.dataLayer.push({
                    'event': 'user-paid'
                })
                window.location.replace('/dashboard');
              }
              this.setState({loading: false})
              
          })
          .catch((error) => {
            this.setState({loading: false})
            console.log(error)
            toast.error('Failed to confirm order')
          });
    }

    handleSubmit = async (stripe, elements) => {
        this.setState({loading: true})
        if (!stripe || !elements) {
          return;
        }
    
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        console.log(result)
        if (result.error) {
          toast.error(result.error.message);
          this.setState({loading: false})
        } else {
            console.log(result)
            this.payAndActivateSubscription(result.token.id, stripe, card)
        }
      }

      

    render() {
        return <Elements stripe={this.state.stripePromise}><ElementsConsumer>
            {({stripe, elements}) => (
            <>
                <h5>Payment informations</h5>
                <div className="center-align">
                    <Card>
                        <Card.Body>
                                <Row>
                                    <CardElement />
                                    <br/>
                                </Row>
                        </Card.Body>
                    </Card>
                    <hr/>
                    <Button disabled={this.state.loading} onClick={() => this.props.backwards()} variant='light'>PREVIOUS</Button>
                    {' '}
                    <Button disabled={this.state.loading} onClick={() => this.handleSubmit(stripe, elements)}>{this.state.loading ? <Loader type="ThreeDots" height={'100%'} color="white" /> : 'PAY NOW '+this.props.selectedPlan.due+'€'}</Button>
                </div>
            </>)
            }
        </ElementsConsumer></Elements>
    }
}

export default FlowPayment;