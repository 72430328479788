import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import './HomePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faGlobe, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import FlowModal from "./FlowModal";
class HomePage extends React.Component {

    constructor() {
        super()
        const loginToken = localStorage.getItem('token')
        this.state = {
            loginToken: loginToken,
            flowModal: loginToken ? true : false,
        }
    }

    render() {
        return <div>
            <FlowModal openModal={this.state.flowModal} handleClose={() => this.setState({flowModal: false})}></FlowModal>
        <Row className='mainBlockLanding'>
            <Col xs={12} sm={12}>
                <h1>Instant number from <b>65+</b> countries</h1>
                <div className='mainBlockSubText'>
                    <p>Need a number from a specific country for registring on a website ? <b>Get one in less than 2 minutes</b></p>
                    <p>Want to hide your true number online ? <b>Hide your number behind an instant number</b></p>
                    <p>Works with Voice, Calls and SMS, Simply sign up and <b>get your number instantly</b></p>
                </div>
                <Button onClick={() => this.setState({flowModal: true})} className='ctaLanding'>Get started</Button>
            </Col>
        </Row>
        <Row className='howBlockLanding'>
            <Col className='howBlockCol' xs={12}>
                <Row>
                <Col xs={12} sm={6}>
                    <FontAwesomeIcon className='cardImageInner' icon={faLock} />
                    <div className="howTitle">Private & Secure</div>
                </Col>
                <Col xs={0} sm={6}>
                </Col>
                <Col xs={12} sm={6}>
                    Stop giving your number when <b>you don't want to</b>, with hidemy.world you can instantly get a number to share to third parties.
                    The best part? You can still receive your calls and text on your real phone number like before.
                </Col>
                </Row>
            </Col>
            <Col className='howBlockCol' xs={12}>
                <Row>
                <Col xs={0} sm={6}>
                </Col>
                <Col xs={12} sm={6}>
                    <FontAwesomeIcon className='cardImageInner' icon={faGlobe} />
                    <div className="howTitle">65+ countries</div>
                </Col>
                <Col xs={0} sm={6}></Col>
                <Col xs={12} sm={6}>
                    A large number of supported countries, this means wethever you are in the world you can easily get an instant phone number.
                    Very useful for theses sneaky registration forms that require a local phone number.
                </Col>
                </Row>
            </Col>
            <Col className='howBlockCol' xs={12}>
                <Row>
                <Col xs={12} sm={6}>
                    <FontAwesomeIcon className='cardImageInner' icon={faCheckCircle} />
                    <div className="howTitle">Quick & Easy</div>
                </Col>
                <Col xs={0} sm={6}>
                </Col>
                <Col xs={12} sm={6}>
                    Interested? You can get started in less than a minute! Just register and get your instant phone number instantly!
                </Col>
                </Row>
            </Col>
        </Row>
        <Row className="secondCTA">
            <Col><Button onClick={() => this.setState({flowModal: true})} className='ctaLanding'>Get started</Button></Col>
        </Row>
        </div>
    }
}

export default HomePage;