import React from "react";
import autoBind from 'react-autobind';
import { Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faMoneyBill, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'
import './FlowModal.css'

class PlanSelection extends React.Component {

    constructor() {
        super();
        this.state = {
            selectedPlan: null
        }
        autoBind(this);
    }

    render() {
        return<>
            <h5>Select your plan</h5>
            <div className="center-align">
                <Row>
                    <Button onClick={() => this.setState({selectedPlan: {id: 'basic', due: 8.99}})} className="plan-selection" variant={this.state.selectedPlan?.id === 'basic' ? "success" : "dark"}>
                        <Row>
                            <Col className="planTitle" sm={12}><FontAwesomeIcon icon={faStar}></FontAwesomeIcon>{' '}BASIC PLAN</Col>
                        </Row>
                        <Row>
                            <div className="subTextFont">€8.99/month - 1 Virtual Number</div>
                        </Row>
                    </Button>
                </Row>
                <Row>
                    <Button onClick={() => this.setState({selectedPlan: {id: 'premium', due: 18.99}})} className="plan-selection" variant={this.state.selectedPlan?.id === 'premium' ? "success" : "dark"}>
                        <Row>
                            <Col className="planTitle" sm={12}><FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon>{' '}PREMIUM PLAN</Col>
                        </Row>
                        <Row>
                            <div className="subTextFont">€18.99/month - Up to 3 virtual numbers</div>
                        </Row>
                    </Button>
                </Row>
                <Row>
                    <Button onClick={() => this.setState({selectedPlan: {id: 'yearly', due: 199}})} className="plan-selection" variant={this.state.selectedPlan?.id === 'yearly' ? "success" : "dark"}>
                        <Row>
                            <Col className="planTitle" sm={12}><FontAwesomeIcon icon={faMoneyBillWave}></FontAwesomeIcon>{' '}YEARLY PLAN</Col>
                        </Row>
                        <Row>
                            <div className="subTextFont">€199/year - Up to 5 virtual numbers</div>
                        </Row>
                    </Button>
                </Row>

                <hr/>
                <Button disabled={!this.state.selectedPlan} onClick={() => this.props.callback(this.state.selectedPlan)}>NEXT</Button>
            </div>
            
        </>
    }
  }
export default PlanSelection;