import React from "react";
import autoBind from 'react-autobind';
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import './FlowModal.css'
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
class EmailSelection extends React.Component {

    constructor() {
        super();
        this.state = {
            selectedEmail: null,
            loading: false,
        }
        autoBind(this);
    }

    register() {
        this.setState({loading: true})
        return fetch(process.env.REACT_APP_API_URL+'/register-passwordless', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.selectedEmail
            })
            })
            .then(response => {
                if(!response.ok) {
                    this.setState({loading: false})
                    toast.error("Unable to sign up")
                }
            return response.json()
            }
            
        )
            .then(data => {

            if(!data.access_token) {
                toast.error(data.error || 'Unable to sign up')
                this.setState({loading: false})
                return
            }

            localStorage.setItem('token', data.access_token)
            this.props.callback(this.state.selectedEmail)
            this.setState({
                loading: false
            })
            })
            .catch((error) => {
                this.setState({loading: false})
                toast.error(error)
            });
    }
    verifyEmail() {
        this.setState({loading: true})
        return fetch(process.env.REACT_APP_API_URL+'/validate-email', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.selectedEmail
            })
            })
            .then(response => {
                if(!response.ok) {
                    toast.error("Invalid email")
                }
            return response.json()
            }
            
        )
            .then(data => {
            if(!data.success) {
                toast.error(data.error || 'Invalid email')
                this.setState({loading: false})
                return
            }

            this.setState({
                loading: false
            })
            this.props.callback(this.state.selectedEmail)
            })
            .catch((error) => {
                this.setState({loading: false})
                toast.error(error)
            });
    }

    render() {
        return<>
            <h5>Enter your email</h5>
            <p>Your email will be used to setup your account and login. You will receive your credentials to login on your dashboard</p>
            <Form.Control disabled={this.state.loading} type="email" value={this.state.email} onChange={(e) => this.setState({selectedEmail: e.target.value})} placeholder="Enter your email" />
            <div className="center-align">
                <hr/>
                <Button disabled={this.state.loading} onClick={() => this.props.backwards()} variant='light'>PREVIOUS</Button>
                {' '}
                <Button disabled={!this.state.selectedEmail || this.state.loading} onClick={this.props.shouldRegister ? this.register : this.verifyEmail}>{this.state.loading ? <Loader type="ThreeDots" height={'100%'} color="white" /> : 'NEXT'}</Button>
            </div>
            
        </>

    }
  }
export default EmailSelection;